import React, { Component } from 'react';

class KalturaPlayer extends Component {

    render() {
        return (
                <div id="kalturaVideoEmbedTarget" style={{
                    height: "80vh"
                }}></div>
        );
    }

    loadKalturaVideo() {
        const playerEmbed =  `
            kWidget.embed({
                'targetId': 'kalturaVideoEmbedTarget',
                'wid': '_${this.props.partnerId}',
                'uiconf_id' : '${this.props.playerId}',
                'entry_id' : '${this.props.entryId}',
                'flashvars':{
                    ${this.props.ks != null ? "'ks': '" + this.props.ks + "'," : ""}
                    'autoPlay': true
                }
            })
        `

        let playerScript = document.createElement('script');
        playerScript.innerHTML = playerEmbed;
        document.body.appendChild(playerScript);
    }

    embedWidgetScript() {
        console.log("did mount");
        const script = document.createElement("script");
        script.src = "https://cdnapi.kaltura.com/p/4210553/sp/421055300/embedIframeJs/uiconf_id/48369133/partner_id/4210553";
        script.async = true;
        script.onload = () => this.loadKalturaVideo();

        document.body.appendChild(script);
    }

    componentDidMount() {
        if (this.props.entryId != null) {
            this.embedWidgetScript();
        }
    }

    componentDidUpdate() {
        console.log("did update");
        console.log(this.props.entryId);
        this.embedWidgetScript();
    }
}
export default KalturaPlayer;