import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TopBanner from './TopBanner';
import ESPClientLocal from '../clients/esp-client-local';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withOktaAuth } from '@okta/okta-react';
import { Grid, Typography } from '@material-ui/core';
import CompetitionCard from './CompetitionCard';
import ListWithRowLink from './ListRowWithLink';
import UserState from '../utils/user-state.service';

const styles = theme => ({
  root: {
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  wrapper: {
    width: "95%",
    marginRight: "auto",
    marginLeft: "auto",
    paddingRight: "10px",
    paddingLeft: "10px"
  },
  competitionCards: {display: 'flex',  justifyContent:'center', alignItems:'center'}
});


class CompetitionsOverview extends Component {
  state = {
    competitions: null,
    user: null
  }

  esp = new ESPClientLocal(this.props.authService);

  async getUser() {
    return await this.props.authService.getUser();
  }

  componentDidMount() {
    this.esp.getStreamableCompetitions().then((competitions) => {
      this.getUser().then(user => {
        UserState.initState(user);
        this.setState({
          competitions: competitions.competitions,
          user: user
        })
      })
    });
  }

  render() {
    const { classes } = this.props;
    console.log(this.state.competitions);

    return (
      <div className={classes.root} >
        <TopBanner titleComponent={
            "Streamable competitions"
        } />
              {
                this.state.competitions ? 
                (
                  <div className={classes.wrapper}>
                  {
                  this.state.competitions.length > 0 ? 
                  <div className={classes.competitionCards}>

                    <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    >
                  {this.state.competitions.map((competition) => {
                      return <Grid item xs={12} spacing={4} key={competition.id}><CompetitionCard id={competition.id} name={competition.name} /></Grid>
                  })}
                  </Grid>
                  </div>
                    :
                    (<Typography variant="h5" align="center">There are no competitions available for streaming at this moment.</Typography>)
                  }
                  </div>
                ):
                (
                  <div style={{textAlign: "center", marginTop: "80px"}}>
                     <CircularProgress style={{color: "#a48c4e"}}/>
                  </div>
                )
              }
              <br></br>
              {this.state.user != null && this.state.user.groups.includes("Okta-Kaltura-Performance-InternalTeam") ?
              <div className={classes.competitionCards}>
                    <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    >
                  <Grid item xs={12} spacing={4}>
                    <ListWithRowLink caption={"Venues"} link={"/venues"} /></Grid>
                  </Grid>
              </div>
              : ""
              }
      
      </div>
    );
   }
}

export default withStyles(styles)(withOktaAuth(CompetitionsOverview));