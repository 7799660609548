const _default = {
    ESP_API_URL: "https://svc-video-streaming-esp-development-4rdw7ij7eq-ew.a.run.app",
    OKTA_ISSUER: "https://rbfa.oktapreview.com/oauth2/default",
    OKTA_CLIENT_ID: "0oats87vf2jXhqteo0x6",
    OKTA_BASE_URL: "https://ssotest.rbfa.be",
    MATCH_STADION_MAPPING: {
        
    },
    KALTURA_PARTNER_ID: "4210553",
    KALTURA_PLAYER_ID: "48369133",
}

const local = {
    ESP_API_URL: "http://localhost:8080",
    OKTA_ISSUER: "https://internalrbfa.okta.com/oauth2/default",
    OKTA_CLIENT_ID: "0oaaazeyyILyxYjNb416",
    OKTA_BASE_URL: "https://internalrbfa.okta.com",
    JUPILER_PRO_LEAGUE: "aTiIj5dQQblCOpVGvpxyyaKBfj0Hpn2JmYUIcU62Kyk=",
    MATCH_STADION_MAPPING: {
        "1K7S5FDegda3lGaNkuIsb_tR42saD7Z45CkGloBKGA8": "charleroi",
        "wM-HU0ot_tPp52cb1KLz_l-Ia-Pc9a77kETjbMPljYY": "standardliege",
        "kHS2NelCkYCI4vId4BmzWg7aTvjfwM83fg3CmixBNdI": "antwerp",
        "FvOkNrNOXZJEXTEnsl10YsONJF2Jd6xleMrs5l9af0g": "anderlecht",
    },
    KALTURA_PARTNER_ID: "4210553",
    KALTURA_PLAYER_ID: "48369133",
}

const development = {
    ESP_API_URL: "http://localhost:8080", // "https://svc-video-streaming-esp-development-4rdw7ij7eq-ew.a.run.app",
    OKTA_ISSUER: "https://internalrbfa.oktapreview.com/oauth2/default",
    OKTA_CLIENT_ID: "0oavcws5nvgbTCx5t0h7",
    OKTA_BASE_URL: "https://internalrbfa.oktapreview.com",
    JUPILER_PRO_LEAGUE: "aTiIj5dQQblCOpVGvpxyyaKBfj0Hpn2JmYUIcU62Kyk=",
    MATCH_STADION_MAPPING: {
        "bkt0PsDTZqQxj9yYdHgKHG7X4Zs4Ra0g9DMMZi4C000": "charleroi",
        "NC_eJ6Onezp7R0ATTbodrnyFrZ1y5cI1RMyCK9mpl6c": "standardliege",
        "MvXN3phO2Tu4kvmDgQ_2AXn0bfVVzbUn3ylTQ5hkhQU": "antwerp",
        "RhHjQg1EiW9TqmPpjUe2oVa3RbaAFcvcYFPPzqqD46s": "anderlecht"
    },
    KALTURA_PARTNER_ID: "4210553",
    KALTURA_PLAYER_ID: "48369133",

}

const production = {
    ESP_API_URL: "https://svc-video-streaming-esp-production-37wb6m5ezq-ew.a.run.app",
    OKTA_ISSUER: "https://internalrbfa.okta.com/oauth2/default",
    OKTA_CLIENT_ID: "0oaaazeyyILyxYjNb416",
    OKTA_BASE_URL: "https://internalrbfa.okta.com",
    JUPILER_PRO_LEAGUE: "QSNEG8qoUOgNs1HuYNNFGkkHIMMTLsHl8m4G8QX-VQw",
    MATCH_STADION_MAPPING: {
        "1K7S5FDegda3lGaNkuIsb_tR42saD7Z45CkGloBKGA8": "charleroi",
        "wM-HU0ot_tPp52cb1KLz_l-Ia-Pc9a77kETjbMPljYY": "standardliege",
        "kHS2NelCkYCI4vId4BmzWg7aTvjfwM83fg3CmixBNdI": "antwerp",
        "FvOkNrNOXZJEXTEnsl10YsONJF2Jd6xleMrs5l9af0g": "anderlecht",
        "svBcilOLB0oflNfjZCJNkdU0xRIYmrYgwgfojSZlQZc": "jjuufg9R7jTqeKk79YzPLjchVacg5UBPng3Ns_9YRGw",
        "YHN-0cEI4JaGSzmURFHpjCksWeLlJXxVhLiU3XHyoFY": "-72BwnPMdi7ILQSEQfVMOkvpmTKNy255o5BVWMT-3Kk"
    },
    KALTURA_PARTNER_ID: "4210553",
    KALTURA_PLAYER_ID: "48369133",
}

export const configurations = {
    "local": local,
    "development": development,
    "production": production
}

export default class Configuration {
    static CONFIG_MAP = {..._default,
                        ...process.env.REACT_APP_SERVICE_PROJECT_TYPE === undefined ? {} : configurations[process.env.REACT_APP_SERVICE_PROJECT_TYPE]};

    static getConfig(config) {
        this.printConfig();
        if (this.CONFIG_MAP !== undefined) {
            return this.CONFIG_MAP[config];
        }
    }

    static printConfig() {
        console.log("Config environment:  " + process.env.REACT_APP_SERVICE_PROJECT_TYPE);
    }
}
