import '../css/VideoThumbnail.css';
import { makeStyles } from '@material-ui/core/styles';
import MatchCameraOverview from "./MatchCameraOverview";
import MatchCard from './MatchCard';
import { Grid } from '@material-ui/core';
import { useOktaAuth } from "@okta/okta-react";
import React, { useState, useEffect } from "react";

const useStyles = makeStyles({
  row: {
    marginBottom: "100px"
  }
});

export default function MatchesOverview(props) {
  const classes = useStyles();
  const { authService, oktaState } = useOktaAuth();

  const moment = require("moment-timezone");

  const [user, setUser] = useState(null);
  useEffect(() => {
    async function getUser() {
      const user = await authService.getUser();
      console.log(user)
      setUser(user);
    }
    getUser();
  }, [authService])

  function userIsInternal() {
    return user.groups.includes("Okta-Kaltura-Performance-InternalTeam")
  }

  function matchHasStream(match) {
    let strStartDate = match.matchDate; // yyyy-mm-dd
    let strStartTime = match.startMatchTime //hh:mm:ss
    let strMatchDt = strStartDate + "T" + strStartTime;

    let startMatchDt = moment(strMatchDt).tz("Europe/Brussels");
    let startMatchMinFifteenMin = startMatchDt.clone().subtract(15, 'minutes');
    let startMatchPlusFourHours = startMatchDt.clone().add(4, 'hours');
    let now = moment.tz(moment(), 'Europe/Brussels')

    return startMatchMinFifteenMin.isSameOrBefore(now) && now.isSameOrBefore(startMatchPlusFourHours);
  }



  return user ? (
    
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      {props.matches.map((match, id) => (
        <div key={id} className={classes.row}>
          <Grid container spacing={1} justify="center" alignItems="center">
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item>
                  <MatchCard match={match.match} />
                </Grid>
                <Grid item>
                  <MatchCameraOverview match={match} streamAvailable={matchHasStream(match.match) || userIsInternal()} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      ))}
    </Grid>
      
  ) : "";
}