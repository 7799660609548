import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import VideoThumbnail from './VideoThumbnail';
import NoVideoThumbnail from './NoVideoThumbnail';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export default function MatchCameraOverview(props) {
  const classes = useStyles();

  const match = props.match.match;
  const streams = props.match.streams;
  const streamAvailable = props.streamAvailable;

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          {streams.map((stream, camId) => (
              streamAvailable ?
              <Grid key={camId} item>
                <VideoThumbnail title={stream.name} thumbnail={match.homeTeam.clubLogoPath} streamUrl={stream.streamUrl} match={match} camId={
                  (camId + 1).toString().padStart(3, '0')
                } />
              </Grid>
              :
              <Grid key={camId} item>
              <NoVideoThumbnail title={stream.name} thumbnail={match.homeTeam.clubLogoPath} streamUrl={stream.streamUrl} match={match} camId={
                (camId + 1).toString().padStart(3, '0')
              } />
              </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
