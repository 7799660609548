import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { Grid, Box } from '@material-ui/core';
import '../css/MatchCard.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "350px",
    backgroundColor: "#a48c4e",
    height: "203px"
  },
  teamName: {
      float: "left",
      marginTop: "9px"
  },
  matchCard: {
    display: "flex",
    alignItems: "left",
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

export default function MatchCard(props) {
  const classes = useStyles();
  const strMatchDate = props.match.matchDate;
  const strStartTime = props.match.startMatchTime
  const strEndTime = props.match.endMatchTime
  const dateParts = strMatchDate.split("-");
  const year = dateParts[0];
  const month = dateParts[1];
  const day = dateParts[2];
  const startTimeParts = strStartTime.split(":");
  const endTimeParts = strEndTime.split(":");

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={2} diraction="column" style={{paddingTop: "19px"}}>
            <Grid item xs={12}>
                <Grid container justify="flex-start" spacing={1} className={classes.teamRow}>
                    <Grid item>
                        <Avatar alt={props.match.homeTeam.clubName} src={props.match.homeTeam.clubLogoPath} className={classes.large}/>
                    </Grid>
                    <Grid item className={classes.teamName}>
                        <Typography component={'span'} variant="body1"><Box fontWeight="fontWeightBold">{props.match.homeTeam.clubName}</Box></Typography>
                    </Grid>
                </Grid>
                <Grid container justify="center" spacing={1}>
                    <Grid item>
                        <Typography variant="subtitle1">{day}-{month}-{year} {startTimeParts[0]}:{startTimeParts[1]}-{endTimeParts[0]}:{endTimeParts[1]}</Typography>
                    </Grid>
                </Grid>
                
                <Grid container justify="flex-start" spacing={1}>
                    <Grid item>
                        <Avatar alt={props.match.awayTeam.clubName} src={props.match.awayTeam.clubLogoPath} className={classes.large}/>
                    </Grid>
                    <Grid item className={classes.teamName}>
                        <Typography component={'span'} variant="body1"><Box fontWeight="fontWeightBold">{props.match.awayTeam.clubName}</Box></Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}