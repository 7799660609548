import '../css/VideoThumbnail.css';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {  Link } from "react-router-dom";

const useStyles = makeStyles({
  row: {
    marginBottom: "100px"
  },
  topBanner: {
    color: "#a48c4e",
    backgroundColor: "#0a0a0a",
    marginBottom: "50px",
    height: "100%",
    paddingTop: "15px",
    paddingBottom: "15px"
  },
  logoutButton: {
    float: "right",
    marginRight: "15px",
    marginTop: "8px",
    backgroundColor: "#a48c4e",
    color: "#fff",
    padding: "10px"
  },
  jupilerProLeagueLogo: {
    height: "50px",
    marginLeft: "7.9%"
  },
  rbfaLogo: {
    marginLeft: "30px",
    height: "50px"
  }
});

export default function MatchesOverview(props) {
  const classes = useStyles();

 return (
    <div className={classes.topBanner} style={props.style}>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} sm={4}>
          <Link to="/">
            <img className={classes.rbfaLogo} alt="The RBFA logo" src="/logo-rbfa.png" />
          </Link>
        </Grid>
        <Grid item xs={12} sm={4}>
        <span className={classes.bannerSpan}>
            <Typography variant="h3" align="center">            
              {props.titleComponent}
            </Typography>
        </span>
        </Grid>
      </Grid>
    </div>
  );
}
