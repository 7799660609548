class UserState {
    private static authService = null
    private static user = null;
    private static isInternalUser: boolean = false;

    static async initState(usr: any) {
        UserState.user = usr;
        UserState.isInternalUser = usr.groups.includes("Okta-Kaltura-Performance-InternalTeam")
    }

    static async userIsInternalStreamingUser() {
        return UserState.isInternalUser;
    }
}

export default UserState