import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TopBanner from './TopBanner';
import ESPClientLocal from '../clients/esp-client-local';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withOktaAuth } from '@okta/okta-react';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
  root: {
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },
  competitionCards: { display: 'flex', justifyContent: 'center', alignItems: 'center' }
});

class VenuesOverview extends Component {
  state = {
    venues: null,
  }

  constructor(props) {
    super(props);
    this.onChangedSelectedVenue = this.onChangedSelectedVenue.bind(this);
  }


  esp = new ESPClientLocal(this.props.authService);

  compareVenues( v1, v2 ) {
    if ( v1.name < v2.name ){
      return -1;
    }
    if ( v1.name > v2.name ){
      return 1;
    }
    return 0;
  }
  

  componentDidMount() {
    this.esp.getStreamableVenues().then((venues) => {
      this.setState({
        venues: venues.venues.sort(this.compareVenues),
      })
    });
  }

  onChangedSelectedVenue(event, newVenue) {
    if (newVenue != null) {
      let route = `/stream/${encodeURIComponent(newVenue.name)}/${encodeURIComponent(newVenue.id)}/001`
      this.props.history.push(route);
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root} >
        <TopBanner titleComponent={
          "Streamable venues"
        } />
        {
          this.state.venues ?
            (
              <div className={classes.wrapper}>
                {
                  this.state.venues.length > 0 ?
                    <Autocomplete
                      id="streamable-venues-search"
                      options={this.state.venues}
                      getOptionLabel={(venue) => venue.name + " (" + venue.clubName + ")"}
                      renderInput={(params) => <TextField {...params} label="Search venue..." variant="outlined" />}
                      style={{minWidth: 400}}
                      onChange={this.onChangedSelectedVenue}
                    />
                    :
                    (<Typography variant="h5" align="center">There are no venues available for streaming at this moment.</Typography>)
                }
              </div>
            ) :
            (
              <div style={{ textAlign: "center", marginTop: "80px" }}>
                <CircularProgress style={{ color: "#a48c4e" }} />
              </div>
            )
        }
      </div>
    );
  }
}

export default withStyles(styles)(withOktaAuth(withRouter(VenuesOverview)));