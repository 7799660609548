import '../css/StreamPlayback.css';
import React, { Component } from 'react';
import TopBanner from './TopBanner';
import ESPClientLocal from '../clients/esp-client-local';
import { withOktaAuth } from '@okta/okta-react';
import KalturaPlayer from './KalturaPlayer';
import Configuration from '../config';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';

class KalturaStreamPlayback extends Component {
  headingTitle = decodeURIComponent(this.props.match.params.headingTitle);
  venueId = this.props.match.params.venueId;
  camId = this.props.match.params.camId;

  state = {
    entryId: null,
    kalturaKs: null,
    noStream: false,
    isLoading: true,
    noStreamAccess: false,
  }

  esp = new ESPClientLocal(this.props.authService);

  componentDidMount() {
    this.loadEntryId();
  }

  onNoStreamMapping() {
    this.setState({
      noStream: true
    })
  }

  onNoStreamAccess() {
    this.setState({
      noStreamAccess: true
    })
  }

  processNewMapping(mapping) {
    this.setState({isLoading: false});
    console.log(mapping)
    if (mapping != null && mapping["entry_id"] != null) {
      this.setState({
        entryId: mapping["entry_id"]
      })

      if (mapping["key"] != null) {
        this.setState({
          kalturaKs: mapping["key"]["key"]
        })
      } else {
       this.onNoStreamAccess();
      }

    } else {
      this.onNoStreamMapping();
    }
  }

  loadEntryId() {
    this.setState({isLoading: true});
    this.esp.getKalturaStreamMapping(decodeURIComponent(this.venueId), this.camId).then(
      (mapping) => {
        this.processNewMapping(mapping)
      }
    )
  }

  render() {
    let titleComponent = (
      <div>
        {this.headingTitle}
      </div>
    );

    let partnerId = Configuration.getConfig("KALTURA_PARTNER_ID");
    let playerId = Configuration.getConfig("KALTURA_PLAYER_ID");
    
    return (
      <div>
        <TopBanner titleComponent={titleComponent} style={{ marginBottom: "20px" }} />
        {this.state.noStream && !this.state.isLoading &&
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
          <h2>No stream configured for this match</h2>
          </div>
        }
        {this.state.noStreamAccess && !this.state.isLoading &&
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
          <h2>Streams are only available from 15 minutes before and 4 hours after the start of the game</h2>
        </div>
        }
        {this.state.isLoading &&
            <div style={{textAlign: "center"}}>
            <CircularProgress style={{color: "#a48c4e"}}/>
          </div>
        }
        {!this.state.noStream && !this.state.noStreamAccess && !this.state.isLoading &&
        <KalturaPlayer
          partnerId={partnerId}
          playerId={playerId}
          entryId={this.state.entryId}
          ks={this.state.kalturaKs}
        />
      }
      </div>
    );
  }
}
export default withOktaAuth(withRouter(KalturaStreamPlayback));