import '../css/VideoThumbnail.css';

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    width: 600,
  },
  media: {
    height: 140,
  },
  thumbnail: {
    maxWidth: 100,
    maxHeight: 100,
  }
});

export default function ListWithRowLink(props) {
  const classes = useStyles();

  return (
    <Link to={props.link} style={{ textDecoration: 'none' }}>
      <Card className={classes.root}>
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h2" align="center">
              {props.caption}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}