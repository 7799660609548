import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MatchesOverview from './MatchesOverview';
import TopBanner from './TopBanner';
import ESPClientLocal from '../clients/esp-client-local';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withOktaAuth } from '@okta/okta-react';
import { Typography } from '@material-ui/core';
import Configuration from '../config';

const styles = theme => ({
  root: {
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  wrapper: {
    width: "95%",
    marginRight: "auto",
    marginLeft: "auto",
    paddingRight: "10px",
    paddingLeft: "10px"
  }
});

class CompetitionOverview extends Component {
  state = {
    competition: null,
    matches: null
  }

  esp = new ESPClientLocal(this.props.authService);
  competitionId = decodeURIComponent(this.props.match.params.competitionId);
  MATCH_STADION_MAPPTING = Configuration.getConfig("MATCH_STADION_MAPPING");


  componentDidMount() {
    this.esp.getCompetition(this.competitionId).then((competition) => {
      this.setState({
        competition: competition
      })
      this.esp.getMatches(this.competitionId).then((matches) => {
       
        this.setState({
          matches: this.transformMatches(matches.matches)
        })
        console.log(this.state.matches);
      })
    });
  }

  getStadiumId(matchId, defaultId = null) {
    if (matchId in this.MATCH_STADION_MAPPTING) {
      return this.MATCH_STADION_MAPPTING[matchId]
    }
    return defaultId;
  }

  transformMatches(matches) {
    return matches.map((match) => {return {
      match: {
        id: match.id,
        homeTeam: {
          clubName: match.home_team.club_name,
          clubLogoPath: match.home_team.club_logo_path,
        },
        awayTeam: {
          clubName: match.away_team.club_name,
          clubLogoPath: match.away_team.club_logo_path,
        },
        venueId: match.venue_id, // match.venue_id
        matchDate: match.match_date,
        startMatchTime: match.start_match_time,
        endMatchTime: match.end_match_time
      },
      streams: [
        {
            "name": "Tactical Wide Angle",
        }
      ]
    }});
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root} >
        <TopBanner titleComponent={
          this.state.competition ?
          (
            this.state.competition.name
          ):
          (
            <div style={{textAlign: "center"}}>
              <CircularProgress style={{color: "#a48c4e"}}/>
            </div>
          )
        } />
              {
                this.state.matches ? 
                (
                  <div className={classes.wrapper}>
                  {
                  this.state.matches.length > 0 ? 
                    (<MatchesOverview matches={this.state.matches} />)
                    :
                    (<Typography variant="h5" align="center">There are no upcoming matches for this competition</Typography>)
                  }
                  </div>
                ):
                (
                  <div style={{textAlign: "center", marginTop: "80px"}}>
                     <CircularProgress style={{color: "#a48c4e"}}/>
                  </div>
                )
              }
      
      </div>
    );
   }
}

export default withStyles(styles)(withOktaAuth(CompetitionOverview));