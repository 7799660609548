import Configuration from "../config";
import { ESPClient } from "./esp-client";

export class NotFoundError extends Error {
    constructor(obj: string) {
        super("Resource not found: " + obj);
        this.name = "NotFoundError";
    }
}

export default class ESPClientLocal extends ESPClient {
    private baseUrl = Configuration.getConfig("ESP_API_URL");
    private oktaAuthService: any;

    constructor(oktaAuthService: any) {
        super();
        this.oktaAuthService = oktaAuthService;
    }

    private async getToken(): Promise<string> {
        let token = await this.oktaAuthService.getAccessToken();
        return token
    }

    private getHeaders(token: string) {
        return new Headers({
            'Authorization': `Bearer ${token}`
        });
    }

    public async getCompetition(competitionId: string): Promise<{}> {
        return await this.getToken().then((token) => {
            return fetch(`${this.baseUrl}/competitions/${encodeURIComponent(competitionId)}`, {
                method: 'GET',
                headers: this.getHeaders(token)
            })
            .then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    const statusCode = res.status
                    if (statusCode === 404) {
                        throw new NotFoundError(`competition ${competitionId}`);
                    } else {
                        throw new Error(`Uncaught error (${statusCode}): ${res.text()}`)
                    }
                }
            })
        });
    }

    public async getMatches(competitionId: string): Promise<{}> {
        return await this.getToken().then((token) => {
            return fetch(`${this.baseUrl}/matches/${encodeURIComponent(competitionId)}`, {
                method: 'GET',
                headers: this.getHeaders(token)
            })
            .then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    const statusCode = res.status
                    if (statusCode === 404) {
                        throw new NotFoundError(`competition ${competitionId}`);
                    } else {
                        throw new Error(`Uncaught error (${statusCode}): ${res.text()}`)
                    }
                }
            })
        });
    }

    public async getStreams(matchId: string): Promise<{}> {
        return await this.getToken().then((token) => {
            return fetch(`${this.baseUrl}/matches/streams/${encodeURIComponent(matchId)}`, {
                method: 'GET',
                headers: this.getHeaders(token)
            })
            .then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    const statusCode = res.status
                    if (statusCode === 404) {
                        throw new NotFoundError(`match ${matchId}`);
                    } else {
                        throw new Error(`Uncaught error (${statusCode}): ${res.text()}`)
                    }
                }
            })
        });
    }

    public async getSignedStreamUrl(venueId: string, camId: string): Promise<any> {
        return await this.getToken().then((token) => {
            return fetch(`${this.baseUrl}/streams/${encodeURIComponent(venueId)}/${encodeURIComponent(camId)}`, {
                method: 'GET',
                headers: this.getHeaders(token)
            })
            .then(res => {
                if (res.ok) {
                    return res.text();
                } else {
                    throw new Error(`Uncaught error: ${res.text()}`)
                }
            })
        });
    }

    public async getKalturaStreamMapping(venueId: string, camId: string): Promise<any> {
        return await this.getToken().then((token) => {
            return fetch(`${this.baseUrl}/streams/kaltura/${encodeURIComponent(venueId)}`, {
                method: 'GET',
                headers: this.getHeaders(token)
            })
            .then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error(`Uncaught error: ${res.text()}`)
                }
            })
            .catch(res => {
                console.error(res);
                return null;
            })
        });
    }

    public async getStreamableCompetitions(): Promise<any> {
        return await this.getToken().then((token) => {
            return fetch(`${this.baseUrl}/streams/competitions`, {
                method: 'GET',
                headers: this.getHeaders(token)
            })
            .then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error(`Uncaught error: ${res.text()}`)
                }
            })
            .catch(res => {
                console.error(res);
                return null;
            })
        });
    }

    public async getStreamableVenues(): Promise<any> {
        return await this.getToken().then((token) => {
            return fetch(`${this.baseUrl}/streams/venues`, {
                method: 'GET',
                headers: this.getHeaders(token)
            })
            .then(res => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error(`Uncaught error: ${res.text()}`)
                }
            })
            .catch(res => {
                console.error(res);
                return null;
            })
        });
    }
}
