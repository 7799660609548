import React, { Component } from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';

import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import Authenticate from './authentication/Authenticate';
import CompetitionOverview from './video/CompetitionOverview';
import CompetitionsOverview from './video/CompetitionsOverview';
import Configuration from './config';
import KalturaStreamPlayback from'./video/KalturaStreamPlayback';
import VenuesOverview from './video/VenuesOverview';
import Home from './video/OktaProfileTest';
export default withRouter(class AppWithRouterAccess extends Component {
  constructor(props) {
    super(props);
    this.onAuthRequired = this.onAuthRequired.bind(this);
  }
  onAuthRequired() {
    this.props.history.push('/authenticate')
  }

  render() {
    if (!process.env.REACT_APP_LOCAL) {
      return (
        <Security issuer={Configuration.getConfig("OKTA_ISSUER")}
                  clientId={Configuration.getConfig("OKTA_CLIENT_ID")}
                  redirectUri={window.location.origin + '/authenticate/callback'}
                  onAuthRequired={this.onAuthRequired}
                  scope={['openid', 'email', 'profile', 'ad_groups']}
                  >
          <Route path='/authenticate' render={() => <Authenticate baseUrl={Configuration.getConfig("OKTA_BASE_URL")} />} />
          <Route path='/authenticate/callback' component={LoginCallback} />
          <SecureRoute exact path='/stream/:headingTitle/:venueId/:camId' component={KalturaStreamPlayback} />
          <SecureRoute exact path='/competition/:competitionId' component={CompetitionOverview} />
          <SecureRoute exact path='/competitions' component={CompetitionsOverview} />
          <SecureRoute exact path='/venues' component={VenuesOverview} />
          <SecureRoute exact path="/home" component={Home}/>
          <SecureRoute exact path='/'>
            <Redirect to={`/competitions`} />
          </SecureRoute>
        </Security>
    );
    } else {
          return (
        <Security issuer={Configuration.getConfig("OKTA_ISSUER")}
                  clientId={Configuration.getConfig("OKTA_CLIENT_ID")}
                  redirectUri={window.location.origin + '/authenticate/callback'}
                  onAuthRequired={this.onAuthRequired} >
          <Route path='/authenticate' render={() => <Authenticate baseUrl={Configuration.getConfig("OKTA_BASE_URL")} />} />
          <Route path='/authenticate/callback' component={LoginCallback} />
          <Route exact path='/stream/:headingTitle/:venueId/:camId' component={KalturaStreamPlayback} />
          <Route exact path='/competitions' component={CompetitionsOverview} />
          <Route exact path='/venues' render={() => <VenuesOverview {...this.props} />} />
          <Route exact path='/competition/:competitionId' component={CompetitionOverview} />
          <Route exact path='/'>
            <Redirect to={`/competitions`} />
          </Route>
        </Security>
    );
    }


  }
});