import '../css/VideoThumbnail.css';

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles({
  root: {
    width: 345,
  },
  media: {
    height: 140,
  },
  thumbnail: {
    maxWidth: 100,
    maxHeight: 100,
  },
});

export default function VideoThumbnail(props) {
  const classes = useStyles();
  const theTitle = `${props.match.homeTeam.clubName} - ${props.match.awayTeam.clubName}: ${props.title}`

  return (
    <Link to={`/stream/${encodeURIComponent(theTitle)}/${props.match.venueId}/${props.camId}`} style={{ textDecoration: 'none' }}>
      <Card className={classes.root}>

        <CardActionArea>
          <CardMedia
            className={classes.media}
            title={props.title}
          >
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={4}>
                <img className={classes.thumbnail} src={props.thumbnail} alt=""></img>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h5">
                  {props.match.homeTeam.clubName}
                </Typography>
              </Grid>
            </Grid>
          </CardMedia>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h2" align="center">
              {props.title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}